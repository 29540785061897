import * as actionType from "../actions/types";

const initialState = {
  userName: "",
  userToken: "",
  loginBtnLoading: false,
  userList: [],
  userTotalPages: 0,
  userTotalList: 0,
  userLoading: false,
  logList: [],
  logTotalPages: 0,
  logTotalList: 0,
  logLoading: false,
  errorLogList: [],
  errorLogTotalPages: 0,
  errorLogTotalList: 0,
  errorLogLoading: false,
  userBtnLoading: false,
  userDetailLoading: false,
  userDetail: null,
  deleteBtnLoading: false,
  cronDuration: "",
  cronDurationBtnLoading: false,
  cronDurationLoading: false,
  logBtnLoading: false,
  apiStatus: "",
  apiData: null,
  drawerOpen: true,
  msg: { open: false, message: "", msgType: "" },
};

const userDataReducer = (state = initialState, action) => {
  switch (action.type) {
    //login
    case actionType.LOGIN_ADMIN:
      return {
        ...state,
        loginBtnLoading: true,
      };

    case actionType.LOGIN_ADMIN_SUCCESS:
      return {
        ...state,
        userName: action.payload.userName,
        userToken: action.payload.userToken,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        loginBtnLoading: false,
      };

    case actionType.LOGIN_ADMIN_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        loginBtnLoading: false,
      };

    //users
    case actionType.ADD_USER:
      return {
        ...state,
        userBtnLoading: true,
        userDetail: null,
        apiStatus: actionType.ADD_USER,
      };

    case actionType.ADD_USER_SUCCESS:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        userList: [action.payload.data, ...state.userList],
        userTotalList: parseInt(state.userTotalList) + 1,
        userBtnLoading: false,
        apiStatus: actionType.ADD_USER_SUCCESS,
      };

    case actionType.ADD_USER_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        userBtnLoading: false,
        apiStatus: actionType.ADD_USER_FAIL,
      };

    case actionType.LIST_USER:
      return {
        ...state,
        msg: { open: false, message: "", msgType: "" },
        userDetail: null,
        userLoading: true,
      };

    case actionType.LIST_USER_SUCCESS:
      return {
        ...state,
        userList: action.payload.data,
        userTotalPages: action.payload.total_pages,
        userTotalList: action.payload.total_list,
        userLoading: false,
      };

    case actionType.LIST_USER_FAIL:
      return {
        ...state,
        userLoading: false,
      };

    case actionType.GET_USER_DETAIL:
      return {
        ...state,
        msg: { open: false, message: "", msgType: "" },
        userDetail: null,
        userDetailLoading: true,
      };

    case actionType.GET_USER_DETAIL_SUCCESS:
      return {
        ...state,
        userDetail: action.payload.data,
        userDetailLoading: false,
      };

    case actionType.GET_USER_DETAIL_FAIL:
      return {
        ...state,
        userDetailLoading: false,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
      };

    case actionType.UPDATE_USER:
      return {
        ...state,
        userBtnLoading: true,
        apiStatus: actionType.UPDATE_USER,
      };

    case actionType.UPDATE_USER_SUCCESS:
      let user_list_update = [...state.userList];
      let userListUpdateIndex = user_list_update.findIndex(
        (x) => x.uid == action.payload.data.uid
      );
      if (userListUpdateIndex >= 0) {
        user_list_update[userListUpdateIndex] = {
          ...user_list_update[userListUpdateIndex],
          ...action.payload.data,
        };
      }

      let user_detail = state.userDetail;
      if (user_detail) {
        user_detail = { ...user_detail, ...action.payload.data };
      }

      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        userList: user_list_update,
        userDetail: user_detail,
        userBtnLoading: false,
        apiStatus: actionType.UPDATE_USER_SUCCESS,
      };

    case actionType.UPDATE_USER_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        userBtnLoading: false,
        apiStatus: actionType.UPDATE_USER_FAIL,
      };

    case actionType.UPDATE_USER_DATA:
      return {
        ...state,
        userBtnLoading: true,
        apiStatus: actionType.UPDATE_USER_DATA,
      };

    case actionType.UPDATE_USER_DATA_SUCCESS:
      let user_data_list_update = [...state.userList];
      let userDataListUpdateIndex = user_data_list_update.findIndex(
        (x) => x.uid == action.payload.data.uid
      );
      if (userDataListUpdateIndex >= 0) {
        user_data_list_update[userDataListUpdateIndex] = {
          ...user_data_list_update[userDataListUpdateIndex],
          ...action.payload.data,
        };
      }

      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        userList: user_data_list_update,
        userBtnLoading: false,
        apiStatus: actionType.UPDATE_USER_DATA_SUCCESS,
      };

    case actionType.UPDATE_USER_DATA_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        userBtnLoading: false,
        apiStatus: actionType.UPDATE_USER_DATA_FAIL,
      };

    case actionType.DELETE_USER:
      return {
        ...state,
        deleteBtnLoading: true,
        apiStatus: actionType.DELETE_USER,
      };

    case actionType.DELETE_USER_SUCCESS:
      let user_list_delete = [...state.userList];
      let userListDeleteIndex = user_list_delete.findIndex(
        (x) => x.uid == action.payload.id
      );
      if (userListDeleteIndex >= 0) {
        user_list_delete.splice(userListDeleteIndex, 1);
      }
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        userList: user_list_delete,
        userTotalList: parseInt(state.userTotalList) - 1,
        deleteBtnLoading: false,
        apiStatus: actionType.DELETE_USER_SUCCESS,
      };

    case actionType.DELETE_USER_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        deleteBtnLoading: false,
        apiStatus: actionType.DELETE_USER_FAIL,
      };

    //settings
    case actionType.ADD_UPDATE_CRON_DURATION:
      return {
        ...state,
        msg: { open: false, message: "", msgType: "" },
        cronDurationBtnLoading: true,
        apiStatus: actionType.ADD_UPDATE_CRON_DURATION,
      };

    case actionType.ADD_UPDATE_CRON_DURATION_SUCCESS:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        cronDuration: action.payload.data?.cronDuration,
        cronDurationBtnLoading: false,
        apiStatus: actionType.ADD_UPDATE_CRON_DURATION_SUCCESS,
      };

    case actionType.ADD_UPDATE_CRON_DURATION_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        cronDurationBtnLoading: false,
        apiStatus: actionType.ADD_UPDATE_CRON_DURATION_FAIL,
      };

    case actionType.LIST_CRON_DURATION:
      return {
        ...state,
        msg: { open: false, message: "", msgType: "" },
        cronDurationLoading: true,
        apiStatus: actionType.LIST_CRON_DURATION,
      };

    case actionType.LIST_CRON_DURATION_SUCCESS:
      return {
        ...state,
        cronDuration: action.payload.data,
        cronDurationLoading: false,
        apiStatus: actionType.LIST_CRON_DURATION_SUCCESS,
      };

    case actionType.LIST_CRON_DURATION_FAIL:
      return {
        ...state,
        cronDurationLoading: false,
        apiStatus: actionType.LIST_CRON_DURATION_FAIL,
      };

    //logs
    case actionType.LIST_LOG:
      return {
        ...state,
        msg: {open: false, message: "", msgType: ""},
        logLoading: true,
      };

    case actionType.LIST_LOG_SUCCESS:
      return {
        ...state,
        logList: action.payload.data,
        logTotalPages: action.payload.total_pages,
        logTotalList: action.payload.total_list,
        logLoading: false,
      };

    case actionType.LIST_LOG_FAIL:
      return {
        ...state,
        logLoading: false,
      };

    case actionType.UPDATE_LOG_STATUS:
      return {
        ...state,
        logLoading: true,
        logBtnLoading: true,
        apiStatus: actionType.UPDATE_LOG_STATUS,
      };

    case actionType.UPDATE_LOG_STATUS_SUCCESS:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        logList: [],
        logTotalPages: 0,
        logTotalList: 0,
        logLoading: false,
        logBtnLoading: false,
        apiStatus: actionType.UPDATE_LOG_STATUS_SUCCESS,
      };

    case actionType.UPDATE_LOG_STATUS_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        logBtnLoading: false,
        logLoading: false,
        apiStatus: actionType.UPDATE_LOG_STATUS_FAIL,
      };

    //error logs
    case actionType.LIST_ERROR_LOG:
      return {
        ...state,
        msg: {open: false, message: "", msgType: ""},
        errorLogLoading: true,
      };

    case actionType.LIST_ERROR_LOG_SUCCESS:
      return {
        ...state,
        errorLogList: action.payload.data,
        errorLogTotalPages: action.payload.total_pages,
        errorLogTotalList: action.payload.total_list,
        errorLogLoading: false,
      };

    case actionType.LIST_ERROR_LOG_FAIL:
      return {
        ...state,
        errorLogLoading: false,
      };

    case actionType.UPDATE_ALERT_MSG:
      return {
        ...state,
        msg: {
          open: action.payload.open,
          message: action.payload.message,
          msgType: action.payload.msgType,
        },
      };

    case actionType.UPDATE_DRAWER_OPEN:
      return {
        ...state,
        drawerOpen: action.payload,
      };

    case actionType.UPDATE_API_DATA:
      return {
        ...state,
        apiData: action.payload,
      };

    case actionType.UPDATE_API_STATUS:
      return {
        ...state,
        apiStatus: action.payload,
      };

    case actionType.LOG_OUT:
      return {
        ...state,
        userName: "",
        userToken: "",
        loginBtnLoading: false,
        userList: [],
        userTotalPages: 0,
        userTotalList: 0,
        userLoading: false,
        logList: [],
        logTotalPages: 0,
        logTotalList: 0,
        logLoading: false,
        errorLogList: [],
        errorLogTotalPages: 0,
        errorLogTotalList: 0,
        errorLogLoading: false,
        userDetailLoading: false,
        userBtnLoading: false,
        userDetail: null,
        deleteBtnLoading: false,
        cronDuration: "",
        cronDurationBtnLoading: false,
        cronDurationLoading: false,
        logBtnLoading: false,
        apiStatus: "",
        apiData: null,
        drawerOpen: true,
        msg: { open: false, message: "", msgType: "" },
      };

    default:
      return { ...state };
  }
};

export default userDataReducer;
