//login
export const LOGIN_ADMIN = "LOGIN_ADMIN";
export const LOGIN_ADMIN_SUCCESS = "LOGIN_ADMIN_SUCCESS";
export const LOGIN_ADMIN_FAIL = "LOGIN_ADMIN_FAIL";

//user
export const ADD_USER = "ADD_USER";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAIL = "ADD_USER_FAIL";
export const LIST_USER = "LIST_USER";
export const LIST_USER_SUCCESS = "LIST_USER_SUCCESS";
export const LIST_USER_FAIL = "LIST_USER_FAIL";
export const GET_USER_DETAIL = "GET_USER_DETAIL";
export const GET_USER_DETAIL_SUCCESS = "GET_USER_DETAIL_SUCCESS";
export const GET_USER_DETAIL_FAIL = "GET_USER_DETAIL_FAIL";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const UPDATE_USER_DATA_SUCCESS = "UPDATE_USER_DATA_SUCCESS";
export const UPDATE_USER_DATA_FAIL = "UPDATE_USER_DATA_FAIL";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

//settings
export const ADD_UPDATE_CRON_DURATION = "ADD_UPDATE_CRON_DURATION";
export const ADD_UPDATE_CRON_DURATION_SUCCESS = "ADD_UPDATE_CRON_DURATION_SUCCESS";
export const ADD_UPDATE_CRON_DURATION_FAIL = "ADD_UPDATE_CRON_DURATION_FAIL";
export const LIST_CRON_DURATION = "LIST_CRON_DURATION";
export const LIST_CRON_DURATION_SUCCESS = "LIST_CRON_DURATION_SUCCESS";
export const LIST_CRON_DURATION_FAIL = "LIST_CRON_DURATION_FAIL";

//logs
export const LIST_LOG = "LIST_LOG";
export const LIST_LOG_SUCCESS = "LIST_LOG_SUCCESS";
export const LIST_LOG_FAIL = "LIST_LOG_FAIL";
export const UPDATE_LOG_STATUS = "UPDATE_LOG_STATUS";
export const UPDATE_LOG_STATUS_SUCCESS = "UPDATE_LOG_STATUS_SUCCESS";
export const UPDATE_LOG_STATUS_FAIL = "UPDATE_LOG_STATUS_FAIL";

//error logs
export const LIST_ERROR_LOG = "LIST_ERROR_LOG";
export const LIST_ERROR_LOG_SUCCESS = "LIST_ERROR_LOG_SUCCESS";
export const LIST_ERROR_LOG_FAIL = "LIST_ERROR_LOG_FAIL";

export const UPDATE_ALERT_MSG = "UPDATE_ALERT_MSG";
export const UPDATE_DRAWER_OPEN = "UPDATE_DRAWER_OPEN";
export const UPDATE_API_DATA = "UPDATE_API_DATA";
export const UPDATE_API_STATUS = "UPDATE_API_STATUS";
export const LOG_OUT = "LOG_OUT";
