import React, {useEffect, useState} from "react";
import {
  Grid,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import moment from "moment";

import Container from "../../components/Container";
import Page from "../../components/Page";
import AlertMsg from "../../components/AlertMsg";

import {UTC_OFFSET} from '../../constants'

import {connect} from "react-redux";
import {
  listErrorLog,
} from "../../redux/actions/userDataActions";

import styles from "./ErrorLogs.module.css";

const ErrorLogs = (props) => {
  const [pageNum, setPageNum] = useState(1);
  const [totalListCount, setTotalListCount] = useState("");
  const [listCount, setListCount] = useState("");
  const [pageLimit, setPageLimit] = useState(50);

  const navigate = useNavigate();

  useEffect(() => {
    setTotalListCount(props.errorLogTotalList);
  }, [props.errorLogTotalList]);

  useEffect(() => {
    setListCount(props.errorLogList?.length);
  }, [props.errorLogList?.length]);

  useEffect(() => {
    getErrorLogsList(1);
  }, [])

  const getErrorLogsList = (page) => {
    let data = {
      page: page
    };
    props.listErrorLog(JSON.stringify(data));
    setPageNum(page);
  };

  const onSelectPage = (page) => {
    getErrorLogsList(page);
  };

  return (
    <Container page={"error_logs"} title={"Error Logs"}>
      <Grid container item md={12} xs={12}>
        <Grid
          item
          md={12}
          xs={12}
          container
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          className={styles.cardView}
        >
          <TableContainer className={styles.tableView}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Error</TableCell>
                  <TableCell>Timestamp</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.errorLogLoading ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <CircularProgress/>
                    </TableCell>
                  </TableRow>
                ) : props.errorLogList?.length > 0 ? (
                  props.errorLogList?.map((item, index) => (
                    <TableRow
                      key={index}
                    >
                      <TableCell>{item?.email ? item?.email : '-'}</TableCell>
                      <TableCell>{item?.type ? item?.type : '-'}</TableCell>
                      <TableCell>{item?.error ? item?.error : '-'}</TableCell>
                      <TableCell>{moment.utc(item?.timestamp).utcOffset(UTC_OFFSET).format("DD-MM-Y hh:mm A")}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <Typography className={styles.noMatchFoundText}>
                        No Error Logs found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid
            item
            md={12}
            xs={12}
            display={"flex"}
            flexDirection={{md: "row", xs: "column"}}
            alignItems={"center"}
          >
            <Grid
              item
              md={6}
              xs={12}
              display={"flex"}
              justifyContent={{md: "flex-start", xs: "unset"}}
              mt={2}
              mb={{md: 2, xs: 0}}
              pl={{md: 2, xs: 0}}
            >
              <Typography className={styles.showingEntriesText}>
                Showing{" "}
                {listCount == 0
                  ? 0
                  : pageNum == 1
                    ? 1
                    : parseInt((pageNum - 1) * pageLimit) + 1}{" "}
                to{" "}
                {listCount == 0
                  ? 0
                  : parseInt((pageNum - 1) * pageLimit) + listCount}{" "}
                of {totalListCount} entries
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              display={"flex"}
              justifyContent={{md: "flex-end", xs: "unset"}}
              mt={2}
              mb={2}
              pr={{md: 2, xs: 0}}
            >
              <Page
                totalPages={props.errorLogTotalPages}
                pageNum={pageNum}
                onSelectPage={onSelectPage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <AlertMsg/>
    </Container>
  )
};

const mapStateToProps = (state) => {
  return {
    errorLogTotalPages: state.userData.errorLogTotalPages,
    errorLogTotalList: state.userData.errorLogTotalList,
    errorLogList: state.userData.errorLogList,
    errorLogLoading: state.userData.errorLogLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listErrorLog: (data) => dispatch(listErrorLog(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorLogs);
